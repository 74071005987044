import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>Welcome to Algo.Vote</h3>
        <a class="btn btn-primary btn-lg" href="https://governance.algorand.foundation/" target='_blank'>Go to Governance</a>
        <p>
          Our website is under construction but please feel free to support us by browsing through our Amazon Affiliate links:
        </p>
        <a target="_blank" rel="noreferrer" href="https://www.amazon.com/b?_encoding=UTF8&tag=techteamapps-20&linkCode=ur2&linkId=0dd567fb5fa31023f3b75f96b7f2e913&camp=1789&creative=9325&node=979455011">Best Sellers in Video Games</a>
        <a target="_blank" rel="noreferrer" href="https://www.amazon.com/b?_encoding=UTF8&tag=techteamapps-20&linkCode=ur2&linkId=d1a44d96d8224fc43fd72f477adb3fb6&camp=1789&creative=9325&node=7072561011">Check out Smartphones</a>
        <a target="_blank" rel="noreferrer" href="https://www.amazon.com/b?_encoding=UTF8&tag=techteamapps-20&linkCode=ur2&linkId=1f3408d35e45b86e93c3e9e07d0c2fc5&camp=1789&creative=9325&node=565108">PC Gaming on the Go</a>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;